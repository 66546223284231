import React from "react"
import Layout from "../components/layout"
import Container from "../components/container"
import SEO from "../components/seo"
import styled from "@emotion/styled"
import inexioLogo from "../images/inexio_logo.svg"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <StyledIndexPage>
      <h1>Wir sind bald wieder für Sie da!</h1>
      <p>
        Sie erreichen uns weiterhin unter{" "}
        <a href="tel:+496819543710">+49 681 9543710</a>, oder unter{" "}
        <a href="mailto:info@raum3.de">info@raum3.de</a>.
      </p>
      <article className="inexio">
        <p>Mit freundlicher Unterstützung von:</p>
        <a target="_blank" rel="noreferrer noopener" href="https://inexio.net/">
          <img src={inexioLogo} alt="inexio Beteiligungs GmbH" />
        </a>
      </article>
    </StyledIndexPage>
  </Layout>
)

const StyledIndexPage = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: calc(100vh - var(--headerHeight) * 2);
  h1 {
    margin-bottom: 0.25rem;
  }
  a {
    font-weight: 600;
  }
  .inexio {
    text-align: center;
    p {
      margin-bottom: 0;
      font-weight: 500;
    }
    img {
      display: block;
      width: 125px;
      margin: 0 auto;
    }
  }
`

export default IndexPage
